body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-bordered-vertical {
  border-right: 2px solid rgba($gray-600, 0.2) !important;
  .nav-item {
      margin-right: -2px;
  }
}

.nav-bordered-vertical li a {
  border: 0 !important;
  padding: 10px 20px !important;
}

.timeline-vertical-line-color:before {
  background: var(--primary);
}

.vertical-timeline-element-icon {
  -webkit-box-shadow: 0 0 0 4px var(--primary), inset 0 2px 0 rgba(0,0,0,.08), 0 3px 0 4px rgba(0,0,0,.05);
  box-shadow: 0 0 0 4px var(--primary), inset 0 2px 0 rgba(0,0,0,.08), 0 3px 0 4px rgba(0,0,0,.05);
}

.vertical-timeline-element-icon i {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -18px;
  font-size: 24px;
}